import React from 'react';
// components
import Button from 'components/Button';
// constants
import { Color } from 'constants/colors';
// styled
import { Container, IconWrapper, Title, Wrapper } from './styled';

export interface CardProps {
  color?: string;
  icon: JSX.Element;
  title: string;
  buttonTitle: string;
  buttonAction: () => void;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ icon, title, buttonTitle, buttonAction, children, color = Color.clearChill }) => {
  return (
    <Wrapper>
      <IconWrapper color={color}>{icon}</IconWrapper>
      <Title>{title}</Title>
      <Container>{children}</Container>
      <Button color={color} onClick={buttonAction}>
        {buttonTitle}
      </Button>
    </Wrapper>
  );
};

export default Card;
