import React from 'react';
// components
import IconButton from 'components/IconButton';
import AnimatedIconButton from 'components/AnimatedIconButton';
// icons
import { ReactComponent as MessageFilledIcon } from 'assets/icons/message-filled.svg';
import { ReactComponent as CheckOutlinedIcon } from 'assets/icons/check-outlined.svg';
import { ReactComponent as VideoCameraOutlinedIcon } from 'assets/icons/video-camera-outlined.svg';
import { ReactComponent as AudioFilledIcon } from 'assets/icons/audio-filled.svg';
// styled
import {
  Avatar,
  BottomContainer,
  ButtonsContainer,
  Wrapper,
  ConversationNick,
  ConversationText,
  PriceText,
  PriceValue,
  TopContainer,
  UploadDescription,
} from './styled';

const transition = {
  duration: 0.15,
  bounce: 0,
};

const variants = {
  exit: { x: '10%' },
  enter: {
    x: '0%',
  },
};

export interface FirstStepProps {
  openDropzoneDialog: () => void;
  setIsPreview: (isPreview: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  image: string | undefined;
}

const FirstStep: React.FC<FirstStepProps> = ({ openDropzoneDialog, setIsLoading, setIsPreview, image }) => {
  return (
    <Wrapper>
      <TopContainer>
        <Avatar>Ol</Avatar>
        <ConversationText>Start your conversation with</ConversationText>
        <ConversationNick>oli_sykes</ConversationNick>
        <PriceText>
          You will pay <PriceValue>$25</PriceValue> for this message
        </PriceText>
      </TopContainer>
      <BottomContainer>
        <UploadDescription>
          Tap <b>Video</b> button to upload your video.
        </UploadDescription>
        <UploadDescription>
          Video length limit is <b>30 seconds</b>
        </UploadDescription>
        <ButtonsContainer variants={variants} initial="exit" animate="enter" exit="exit" transition={transition}>
          <IconButton icon={<MessageFilledIcon width="14px" height="14px" />} />
          <AnimatedIconButton
            onClick={() => {
              openDropzoneDialog();
              setIsPreview(true);
              setIsLoading(true);
            }}
            icon={image ? <CheckOutlinedIcon /> : <VideoCameraOutlinedIcon />}
          />
          <IconButton icon={<AudioFilledIcon />} />
        </ButtonsContainer>
      </BottomContainer>
    </Wrapper>
  );
};

export default FirstStep;
