import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  width: 100vw;
  height: fit-content;
`;

export const Avatar = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.silver};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 24px;
  border-radius: 100%;
  align-self: center;
`;
