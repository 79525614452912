import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// components
import AnimatedPage from 'components/AnimatedPage';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import Card from './components/Card';
// hooks
import { useHeaderContext } from 'providers/HeaderProvider';
// constants
import { Color } from 'constants/colors';
// icons
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as MessageFilledIcon } from 'assets/icons/message-filled.svg';
import { ReactComponent as SmileFilledIcon } from 'assets/icons/smile-filled.svg';
import { ReactComponent as NotificationFilledIcon } from 'assets/icons/notification-filled.svg';
import { ReactComponent as ThunderboltFilledIcon } from 'assets/icons/thunderbolt-filled.svg';
// styled
import {
  Avatar,
  CardPrice,
  CardsContainer,
  CardText,
  Container,
  Description,
  LikePercentage,
  Nick,
  NickContainer,
  NoContentYet,
  SeeMoreLink,
  TabsContainer,
  Wrapper,
} from './styled';

const StorePage: React.FC = () => {
  const { setHeaderValues } = useHeaderContext();
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderValues({
      isPrimary: true,
      shouldBeAnimated: true,
    });
  }, [setHeaderValues]);

  const cards = useMemo(
    () => [
      {
        title: 'Private message',
        icon: <MessageFilledIcon />,
        buttonTitle: 'Start a chat',
        color: Color.bitOfBerry,
        buttonAction: () => {
          navigate('private-message');
        },
        cardContent: (
          <CardText>
            <CardPrice>25$</CardPrice> /message
          </CardText>
        ),
      },
      {
        title: '1 to 1 sessions',
        icon: <SmileFilledIcon />,
        buttonTitle: 'Start a session',
        color: Color.majorelleBlue,
        buttonAction: () => {},
        cardContent: (
          <CardText>
            from <CardPrice>300$</CardPrice> /month
          </CardText>
        ),
      },
      {
        title: 'Group session',
        icon: <NotificationFilledIcon />,
        buttonTitle: 'Start a session',
        color: Color.bulmaHair,
        buttonAction: () => {},
        cardContent: (
          <CardText>
            <CardPrice>45$</CardPrice> /session
          </CardText>
        ),
      },
      {
        title: 'Premium content',
        icon: <ThunderboltFilledIcon />,
        buttonTitle: 'Subscription',
        color: Color.sportsFan,
        buttonAction: () => {},
        cardContent: (
          <CardText>
            <CardPrice>65$</CardPrice> /month
          </CardText>
        ),
      },
    ],
    [navigate],
  );

  return (
    <AnimatedPage>
      <Wrapper>
        <Container>
          <Avatar>Ol</Avatar>
          <NickContainer>
            <Nick>oli_sykes</Nick>
            <LikeIcon />
            <LikePercentage>94%</LikePercentage>
          </NickContainer>
          <Description>
            Hi! here to make dreamz come true. You can also request a tattoo design instead of msg...{' '}
            <SeeMoreLink href="#">See more</SeeMoreLink>
          </Description>
          <TabsContainer>
            <Tabs showDivider>
              <Tab icon={<GridIcon />}>
                <CardsContainer>
                  {cards.map((card, index) => (
                    <Card
                      color={card.color}
                      icon={card.icon}
                      title={card.title}
                      buttonTitle={card.buttonTitle}
                      buttonAction={card.buttonAction}
                      key={`${card.title}-${index}`}
                    >
                      {card.cardContent}
                    </Card>
                  ))}
                </CardsContainer>
              </Tab>
              <Tab icon={<PlayIcon />}>
                <NoContentYet>No Content Yet!</NoContentYet>
              </Tab>
            </Tabs>
          </TabsContainer>
        </Container>
      </Wrapper>
    </AnimatedPage>
  );
};

export default StorePage;
