import styled from '@emotion/styled/macro';
import { css } from '@emotion/css';
import { motion } from 'framer-motion';
import { detect } from 'detect-browser';
import { Color } from 'constants/colors';

const browser = detect();

export const Wrapper = styled(motion.nav)`
  position: sticky;
  height: 62px;
  top: 0;
  z-index: 1;
  ${() =>
    browser?.name === 'ios' &&
    `@media all and (display-mode: standalone) {
          height: 102px;
        }`}
`;

css`
  @media all and (display-mode: standalone) {
    padding-top: 36px;
  }
`;

export const Container = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${() =>
    browser?.name === 'ios' &&
    `@media all and (display-mode: standalone) {
          padding-top: 56px;
        }`}
`;

export const Logo = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 8px;
  background-color: ${Color.orochimaru};
  margin-right: 8px;
`;

export const Title = styled.div`
  font-family: 'Inter-Bold', sans-serif;
  color: ${Color.shadowMountain};
  font-size: 16px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Profile = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.moreThanAWeek};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 12px;
  margin-left: 20px;
  border-radius: 100%;
  cursor: pointer;
`;

export const NavContainer = styled(motion.div)`
  color: ${Color.blackSuede};
  display: flex;
`;
