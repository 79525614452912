import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Color } from 'constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsContainer = styled.div<{ showDivider: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 12px;
  width: 100%;
  border-bottom: ${({ showDivider }) => (showDivider ? `1px solid ${Color.snowFlake}` : 'none')};

  & > * {
    margin-right: 30px;
  }
  & > button:last-of-type {
    margin-right: 0;
  }
  & > span {
    margin-right: 0;
  }
`;

export const TabContent = styled.div`
  margin-top: 32px;
`;

export const TabUnderline = styled.span<{ width: number; left: number }>`
  display: ${({ left }) => (left ? 'inline-block' : 'none')};
  position: absolute;
  bottom: 0;
  width: 56px;
  left: ${({ left }) => left && left}px;
  height: 2px;
  transition: 150ms all cubic-bezier(1, 0, 0, 1);
  background-color: ${Color.clearChill};
`;

export const Tab = styled.button<{ selected?: boolean }>`
  color: ${Color.moreThanAWeek};
  cursor: pointer;
  min-width: 56px;
  display: flex;
  justify-content: center;
  ${({ selected }) =>
    selected &&
    css`
      color: ${Color.clearChill};
    `}
`;
