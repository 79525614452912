import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import { Color } from 'constants/colors';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 38px;
`;

export const MessageText = styled.div`
  font-size: 12px;
  color: ${Color.nero};
  padding-bottom: 28px;
  text-align: center;
`;

export const Preview = styled.div`
  width: 156px;
  height: 256px;
  border: 2px solid ${Color.clearChill};
  border-radius: 12px;
  background-color: ${Color.whiteSmoke};
  position: relative;
  margin-left: 98px;
  @media (max-width: 332px) {
    margin-left: 38px;
  }
`;

export const PreviewImage = styled.img`
  width: 156px;
  height: 256px;
  border-radius: 12px;
  position: relative;
  margin-left: 98px;
  object-fit: cover;
  @media (max-width: 332px) {
    margin-left: 38px;
  }
`;

export const PreviewContainer = styled.div`
  position: relative;
`;

export const Avatar = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.preciousPersimmon};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  border-radius: 100%;
  align-self: center;
  top: 0;
  right: -42px;
`;

export const PrepareText = styled.div`
  font-size: 14px;
  color: ${Color.shadowMountain};
  position: absolute;
  bottom: 12px;
  right: 10px;
`;

export const ConfirmText = styled.div`
  font-size: 14px;
  color: ${Color.shadowMountain};
  line-height: 22px;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 38px;
`;

export const ButtonsContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 28px;
  margin-top: 30px;
`;
