import React from 'react';
// libs
import { BrowserRouter } from 'react-router-dom';
// components
import Routes from './Routes';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default Router;
