import React from 'react';
// components
import Loader from '../Loader';
// styled
import { StyledIconButton, Icon } from './styled';

export interface AnimatedIconButtonProps {
  icon: string | JSX.Element;
  onClick?: () => void;
  isLoading?: boolean;
}

const AnimatedIconButton: React.FC<AnimatedIconButtonProps> = ({ onClick, isLoading, icon }) => {
  return (
    <StyledIconButton onClick={onClick} disabled={isLoading}>
      {isLoading ? <Loader /> : typeof icon === 'string' ? <Icon src={icon} /> : icon}
    </StyledIconButton>
  );
};

export default AnimatedIconButton;
