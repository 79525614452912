import { css } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import './fonts.css';

export const GlobalStyles = css`
  ${emotionNormalize};

  #root {
    width: 100vw;
    min-height: 100vh;
    position: relative;
    background-color: white;
  }

  body * {
    font-family: 'Inter-Regular', sans-serif;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  h1 {
    font-size: 2.5em;
    margin: 0 0 30px 0;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 1em;
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  button {
    background: none;
  }

  body {
    margin: 0;
  }
`;
