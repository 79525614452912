import React, { useEffect, useState } from 'react';
// libs
import { AnimatePresence } from 'framer-motion';
// components
import Button from 'components/Button';
import Loader from 'components/Loader';
// icons
import { ReactComponent as MessageOutlinedIcon } from 'assets/icons/message-outlined.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/done.svg';
// constants
import { Color } from 'constants/colors';
// styled
import {
  Avatar,
  ButtonsContainer,
  HeaderText,
  IconWrapper,
  InfoContainer,
  LoaderContainer,
  NoteText,
  PriceText,
  Wrapper,
} from './styled';

export interface ModalContentProps {
  closeModal: () => void;
}

const transition = {
  duration: 0.15,
  bounce: 0,
};

const variants = {
  exit: { opacity: 0, transition: transition },
  enter: {
    opacity: 1,
    transition,
  },
};

const ModalContent: React.FC<ModalContentProps> = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let isLoadingTimer: ReturnType<typeof setTimeout>;
    let isLoadedTimer: ReturnType<typeof setTimeout>;

    const setupTimeouts = () => {
      isLoadingTimer = setTimeout(() => {
        setIsLoading(false);
        setIsLoaded(true);
        isLoadedTimer = setTimeout(() => {
          closeModal();
        }, 900);
      }, 3000);
    };

    setupTimeouts();

    return () => {
      clearTimeout(isLoadingTimer);
      clearTimeout(isLoadedTimer);
    };
  }, [closeModal]);

  const handleApplePayButtonClick = () => setIsLoading(true);

  return (
    <Wrapper>
      {!isLoading && !isLoaded ? (
        <>
          <HeaderText>You are going to pay</HeaderText>
          <PriceText>$ 25.00 USD</PriceText>
          <InfoContainer>
            to <Avatar>Ol</Avatar> oly_sykes for <MessageOutlinedIcon /> private chat
          </InfoContainer>
          <NoteText>
            Note: No worries, your money will be refunded if there is a 7 days quiteness on the creator side
          </NoteText>
          <ButtonsContainer>
            <Button color={Color.nero} onClick={handleApplePayButtonClick}>
              Pay with Apple Pay
            </Button>
            <Button color={Color.white} textColor={Color.blackSuede} showBorder>
              Pay with Credit Card
            </Button>
            <Button color={Color.white} textColor={Color.blackSuede} showBorder>
              Other options
            </Button>
          </ButtonsContainer>
        </>
      ) : (
        <LoaderContainer>
          <AnimatePresence exitBeforeEnter>
            {isLoaded ? (
              <IconWrapper variants={variants} initial="exit" animate="enter" exit="exit" key="loaded">
                <DoneIcon />
              </IconWrapper>
            ) : (
              <IconWrapper variants={variants} initial="exit" animate="enter" exit="exit" key="loading">
                <Loader width={60} height={60} borderWidth={4} color={Color.clearChill} />
              </IconWrapper>
            )}
          </AnimatePresence>
        </LoaderContainer>
      )}
    </Wrapper>
  );
};

export default ModalContent;
