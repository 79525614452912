import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const StyledButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(140, 140, 140, 0.3);
  cursor: pointer;
`;

export const Text = styled.div`
  font-size: 16px;
  color: ${Color.nero};
  font-family: 'Inter-SemiBold', sans-serif;
`;

export const Title = styled(Text)``;

export const Price = styled(Text)`
  margin-right: 8px;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.shadowMountain};
`;
