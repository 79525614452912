import React, { memo } from 'react';
// libs
import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import { useNavigationType } from 'react-router-dom';
// hooks
import { useHeaderContext, isIOS } from 'providers/HeaderProvider';

const MotionMain = styled(motion.main)`
  position: absolute;
`;

const transition = { ease: [1, 0, 0, 1], duration: 0.3 };

export interface AnimatedPageProps {
  children: React.ReactNode;
}

function AnimatedPage({ children, ...props }: AnimatedPageProps) {
  const navigationType = useNavigationType();

  const { isNavigatedByBackButton } = useHeaderContext();

  const variants = {
    initial: (action: string) => {
      const isPush = action === 'PUSH';
      if (action === 'POP' && !isNavigatedByBackButton.current) {
        return {
          x: 0,
          transition,
        };
      }
      return {
        x: isPush ? '100%' : '-100%',
        transition,
      };
    },

    animate: () => {
      return {
        x: 0,
        transition,
      };
    },

    exit: (action: string) => {
      const isPop = action === 'POP';
      if (isPop && !isNavigatedByBackButton.current) {
        return {};
      }
      return {
        x: isPop ? '100%' : '-100%',
        transition,
      };
    },
  };

  return (
    <MotionMain
      custom={navigationType}
      initial={'initial'}
      animate={'animate'}
      exit="exit"
      variants={variants}
      onAnimationComplete={() => {
        isNavigatedByBackButton.current = !isIOS;
      }}
      {...props}
    >
      {children}
    </MotionMain>
  );
}

export default memo(AnimatedPage);
