import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import { Color } from 'constants/colors';

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(151, 151, 151, 0.9);
  z-index: 10;
`;

export const Content = styled(motion.div)`
  max-width: 500px;
  width: 94%;
  background-color: ${Color.white};
  border-radius: 12px;
  position: relative;
  z-index: 15;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;
