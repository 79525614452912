import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import { Color } from 'constants/colors';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: 38px;
`;

export const Avatar = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.silver};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 24px;
  border-radius: 100%;
  align-self: center;
`;

export const ConversationText = styled.div`
  font-size: 14px;
  color: ${Color.nero};
  padding-top: 24px;
`;

export const ConversationNick = styled.div`
  font-size: 14px;
  color: ${Color.nero};
  font-family: 'Inter-SemiBold', sans-serif;
  padding-top: 8px;
`;

export const PriceText = styled.div`
  font-size: 20px;
  color: ${Color.nero};
  padding-top: 24px;
  text-align: center;
`;

export const PriceValue = styled.span`
  font-size: 24px;
  color: ${Color.nero};
  font-family: 'Inter-SemiBold', sans-serif;
`;

export const UploadDescription = styled.div`
  font-size: 14px;
  color: ${Color.nero};
  line-height: 22px;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 146px;
`;

export const ButtonsContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 28px;
  margin-top: 30px;
`;
