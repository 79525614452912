import styled from '@emotion/styled/macro';
import { detect } from 'detect-browser';
import { Color } from 'constants/colors';

const browser = detect();

export const Wrapper = styled.div`
  width: 100vw;
  height: fit-content;
`;

export const GetResponseText = styled.div`
  color: ${Color.white};
  font-size: 24px;
  margin-top: 16px;
  font-family: 'Inter-Medium', sans-serif;
`;

export const ProfileName = styled.a`
  color: ${Color.white};
  font-size: 24px;
  margin-top: 8px;
  text-decoration: underline;
  font-family: 'Inter-SemiBold', sans-serif;
`;

export const ProfileContainer = styled.div`
  background-color: ${Color.bitOfBerry};
  margin-top: -66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 32px;
  ${() =>
    browser?.name === 'ios' &&
    `@media all and (display-mode: standalone) {
          margin-top: -106px;
          padding-top: 128px;
        }`}
`;

export const Avatar = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.white15};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 24px;
  border-radius: 100%;
  align-self: center;
`;

export const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

export const Title = styled.div`
  color: ${Color.nero};
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 18px;
  letter-spacing: 1px;
  font-family: 'Inter-SemiBold', sans-serif;
`;

export const PricingButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
`;
