import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const StyledButton = styled.button<{
  fullWidth: boolean;
  color: string;
  textColor: string;
  showBorder: boolean;
}>`
  padding: 8px 10px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  border-radius: 2px;
  color: ${({ textColor }) => textColor};
  font-size: 14px;
  background-color: ${({ color }) => color};
  cursor: pointer;
  border: ${({ showBorder }) => (showBorder ? `1px solid ${Color.orochimaru}` : '1px solid transparent')};
`;
