import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const Wrapper = styled.div`
  padding: 0 16px 16px;
  width: 100vw;
  height: fit-content;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Avatar = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.silver};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 24px;
  border-radius: 100%;
  align-self: center;
`;

export const Nick = styled.div`
  color: ${Color.nero};
  font-size: 20px;
  font-family: 'Inter-SemiBold', sans-serif;
  margin-right: 10px;
`;

export const LikePercentage = styled.div`
  font-size: 16px;
  color: ${Color.nero};
  font-family: 'Inter-SemiBold', sans-serif;
  align-self: flex-end;
  margin-left: 10px;
`;

export const NickContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Color.blackSuede};
  margin-top: 10px;
`;

export const SeeMoreLink = styled.a`
  display: inline-block;
  color: ${Color.clearChill};
  font-size: 14px;
`;

export const Description = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${Color.blackSuede};
  margin-top: 14px;
  line-height: 22px;
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`;

export const CardText = styled.div`
  font-size: 14px;
  color: ${Color.blackSuede};
`;

export const CardPrice = styled.span`
  font-size: 14px;
  color: ${Color.blackSuede};
  font-family: 'Inter-SemiBold', sans-serif;
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;
  flex-wrap: wrap;
`;

export const NoContentYet = styled.div`
  font-size: 14px;
  color: ${Color.blackSuede};
  font-family: 'Inter-SemiBold', sans-serif;
  text-align: center;
`;
