import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

const animation = css`
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledLoader = styled.div<{ width: number; height: number; borderWidth: number; color: string }>`
  display: inline-block;
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  z-index: 100;
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ width }) => width - 16}px;
    height: ${({ height }) => height - 16}px;
    margin: 8px;
    border: ${({ borderWidth }) => borderWidth}px solid ${({ color }) => color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => color} transparent transparent transparent;
  }
  & div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  & div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  & div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  ${animation}
`;
