import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StartedChatText = styled.div`
  font-size: 12px;
  color: ${Color.nero};
  margin-top: 8px;
  line-height: 20px;
  text-align: center;
`;

export const PreviewImage = styled.img`
  width: 156px;
  height: 256px;
  border-radius: 12px;
  object-fit: cover;
  border: none;
  @media (max-width: 332px) {
    margin-left: 80px;
  }
`;

export const PostedTime = styled.div`
  font-size: 14px;
  color: ${Color.shadowMountain};
  position: absolute;
  bottom: 12px;
  right: 10px;
`;

export const PreviewImageContainer = styled.div`
  position: relative;
  margin-left: 98px;
  border: none;
  margin-top: 28px;
`;

export const LikeIconWrapper = styled.div`
  color: ${Color.shadowMountain};
  margin-top: 58px;
  margin-bottom: 18px;
`;

export const MessageSentText = styled.div`
  font-size: 14px;
  color: ${Color.nero};
  line-height: 22px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const Avatar = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.preciousPersimmon};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  border-radius: 100%;
  align-self: center;
  top: 0;
  right: -42px;
`;
