import React, { useEffect, useMemo } from 'react';
// libs
import { useNavigate } from 'react-router-dom';
// components
import PriceButton from 'components/PriceButton';
import AnimatedPage from 'components/AnimatedPage';
// hooks
import { useHeaderContext } from 'providers/HeaderProvider';
// constants
import { Color } from 'constants/colors';
// styled
import {
  Avatar,
  GetResponseText,
  PricingButtonsContainer,
  PricingContainer,
  ProfileContainer,
  ProfileName,
  Title,
  Wrapper,
} from './styled';

const PrivateMessagePage = () => {
  const navigate = useNavigate();
  const { setHeaderValues, isNavigatedByBackButton } = useHeaderContext();

  const pricingButtons = useMemo(
    () => [
      {
        title: 'Private message',
        price: 25,
        onClick: () => {
          navigate('private-message-upload');
        },
      },
      { title: 'Song request', price: 65, onClick: () => {} },
      { title: 'Singer consultation', price: 150, onClick: () => {} },
    ],
    [navigate],
  );

  useEffect(() => {
    setHeaderValues({
      isPrimary: false,
      title: 'Private message',
      color: Color.white,
      bgColor: Color.bitOfBerry,
      showDivider: false,
      handleBackClick: () => {
        isNavigatedByBackButton.current = true;
        setHeaderValues({ shouldBeAnimated: true });
        navigate(-1);
      },
    });
  }, [isNavigatedByBackButton, navigate, setHeaderValues]);

  return (
    <AnimatedPage>
      <Wrapper>
        <ProfileContainer>
          <Avatar>Ol</Avatar>
          <GetResponseText>Get response from</GetResponseText>
          <ProfileName>oli_sykes</ProfileName>
        </ProfileContainer>
        <PricingContainer>
          <Title>PRIVATE MESSAGE PRICING</Title>
          <PricingButtonsContainer>
            {pricingButtons.map((button, index) => (
              <PriceButton
                key={`${button.title}-${index}`}
                title={button.title}
                price={button.price}
                onClick={button.onClick}
              />
            ))}
          </PricingButtonsContainer>
        </PricingContainer>
      </Wrapper>
    </AnimatedPage>
  );
};

export default PrivateMessagePage;
