import React, { RefObject } from 'react';
// libs
import Dropzone, { DropzoneRef } from 'react-dropzone';
// styled
import { Wrapper } from './styled';

type UploadProps = {
  proceedImage: (image: string) => void;
  children: React.ReactNode;
  dropzoneRef: RefObject<DropzoneRef>;
  onFileDialogCancel?: () => void;
};

const Upload: React.FC<UploadProps> = ({ proceedImage, children, onFileDialogCancel, dropzoneRef }) => {
  async function readFile(file: File) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e?.target?.result);
      reader.readAsDataURL(file);
    });
  }

  async function onFileUpload(file: File) {
    const image = await readFile(file);
    return image as string;
  }

  const handleOnDrop = (acceptedFiles: unknown[]) => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      if (file instanceof File) {
        onFileUpload(file).then((image) => proceedImage(image));
      }
    } else {
      alert('You can upload only single file');
    }
  };

  return (
    <Dropzone noClick noKeyboard ref={dropzoneRef} onFileDialogCancel={onFileDialogCancel} onDrop={handleOnDrop}>
      {({ getRootProps, getInputProps }) => (
        <Wrapper {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
        </Wrapper>
      )}
    </Dropzone>
  );
};

export default Upload;
