import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import { detect } from 'detect-browser';
import { Color } from 'constants/colors';

const browser = detect();

export const Wrapper = styled(motion.nav)`
  position: sticky;
  height: 60px;
  top: 0;
  background-color: transparent;
  z-index: 1;
  ${() =>
    browser?.name === 'ios' &&
    `@media all and (display-mode: standalone) {
          height: 100px;
        }`}
`;

export const Divider = styled(motion.div)`
  position: sticky;
  top: 60px;
  width: 100%;
  height: 2px;
  background-color: ${Color.snowFlake};
  ${() =>
    browser?.name === 'ios' &&
    `@media all and (display-mode: standalone) {
         top: 100px;
        }`}
`;

export const Container = styled.div<{ color: string }>`
  padding: 16px 24px 16px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ color }) => color};
  width: 100%;
  ${() =>
    browser?.name === 'ios' &&
    `@media all and (display-mode: standalone) {
          padding-top: 56px;
        }`}
`;

export const Title = styled.div`
  font-size: 16px;
  font-family: 'Inter-SemiBold', sans-serif;
  color: inherit;
`;

export const MoreIconWrapperAnimated = styled(motion.div)`
  margin-right: -12px;
  cursor: pointer;
`;

export const MoreIconWrapper = styled.div`
  margin-right: -12px;
  cursor: pointer;
`;
