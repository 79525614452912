import React from 'react';
// constants
import { Color } from 'constants/colors';
// styled
import { StyledIconButton, Icon } from './styled';

export interface IconButtonProps {
  icon: string | JSX.Element;
  borderColor?: string;
  color?: string;
  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  borderColor = Color.snowFlake,
  color = Color.blackSuede,
}) => {
  return (
    <StyledIconButton onClick={onClick} borderColor={borderColor} color={color}>
      {typeof icon === 'string' ? <Icon color={color} src={icon} /> : icon}
    </StyledIconButton>
  );
};

export default IconButton;
