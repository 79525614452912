import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const StyledIconButton = styled.button<{ borderColor: string; color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ color }) => color};
  border-radius: 100%;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 14px;
  height: 14px;
  color: ${Color.white};
`;
