import React from 'react';
// libs
import { Routes as ReactRouterDomRoutes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
// hooks
import { useHeaderContext } from 'providers/HeaderProvider';
import useScrollToTop from 'hooks/useScrollToTop';
// components
import PrimaryHeader from 'components/PrimaryHeader';
import SecondaryHeader from 'components/SecondaryHeader';
// pages
import StorePage from 'pages/StorePage';
import PrivateMessagePage from 'pages/PrivateMessagePage';
import PrivateMessageUploadPage from 'pages/PrivateMessageUploadPage';

const Routes = () => {
  const location = useLocation();
  const { isPrimary } = useHeaderContext();

  useScrollToTop();

  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        {isPrimary ? <PrimaryHeader /> : <SecondaryHeader />}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter={false} initial={false}>
        <ReactRouterDomRoutes location={location} key={location.pathname}>
          <Route path="/">
            <Route index element={<Navigate to="store" replace />} />
            <Route path="store">
              <Route index element={<StorePage />} />
              <Route path="private-message">
                <Route index element={<PrivateMessagePage />} />
                <Route path="private-message-upload" element={<PrivateMessageUploadPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={'/'} replace />} />
        </ReactRouterDomRoutes>
      </AnimatePresence>
    </>
  );
};

export default Routes;
