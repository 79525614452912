import React from 'react';
// components
import Button from 'components/Button';
// icons
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
// styled
import {
  Avatar,
  ButtonWrapper,
  LikeIconWrapper,
  MessageSentText,
  PostedTime,
  PreviewImage,
  PreviewImageContainer,
  StartedChatText,
  Wrapper,
} from './styled';

export interface ThirdStepProps {
  image: string | undefined;
}

const ThirdStep: React.FC<ThirdStepProps> = ({ image }) => {
  return (
    <Wrapper>
      <StartedChatText>
        <b>You</b> started a chat with <b>oli_sykes.</b>
      </StartedChatText>
      <PreviewImageContainer>
        <PreviewImage src={image} />
        <PostedTime>Tue, 9:41</PostedTime>
        <Avatar>Ji</Avatar>
      </PreviewImageContainer>
      <LikeIconWrapper>
        <LikeIcon width={28} height={28} />
      </LikeIconWrapper>
      <MessageSentText>
        Your message was sent to <b>oli_sykes.</b>
      </MessageSentText>
      <MessageSentText>
        Average response time is <b>20 minutes.</b>
      </MessageSentText>
      <ButtonWrapper>
        <Button>Go to Inbox</Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ThirdStep;
