import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const StyledIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: 6px solid ${Color.iceEffect};
  color: ${Color.white};
  background-color: ${Color.clearChill};
  border-radius: 100%;
  cursor: pointer;
  &:disabled {
    cursor: auto;
    pointer-events: none;
    background-color: ${Color.brokenBlue};
  }
`;

export const Icon = styled.img`
  width: 14px;
  height: 14px;
  color: ${Color.white};
`;
