import React, { useState, ReactElement, useRef, useEffect, useCallback } from 'react';
// styled
import { Tab, TabContent, TabsContainer, TabUnderline, Wrapper } from './styled';

export interface TabsProps {
  children: ReactElement[];
  showDivider?: boolean;
}

const Tabs: React.FC<TabsProps> = ({ children, showDivider = false }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const refs = useRef(new Array(children.length));

  const setTabPosition = useCallback(() => {
    const currentTab = refs.current[selectedTab];
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
  }, [selectedTab]);

  useEffect(() => {
    setTabPosition();
    window.addEventListener('resize', setTabPosition);
    return () => window.removeEventListener('resize', setTabPosition);
  }, [selectedTab, setTabPosition]);

  return (
    <Wrapper>
      <TabsContainer showDivider={showDivider}>
        {children.map((item, index) => (
          <Tab
            ref={(el) => (refs.current[index] = el)}
            key={index}
            selected={selectedTab === index}
            onClick={() => setSelectedTab(index)}
          >
            {item.props?.title}
            {item.props?.icon}
          </Tab>
        ))}
        <TabUnderline width={tabUnderlineWidth} left={tabUnderlineLeft} />
      </TabsContainer>
      <TabContent>{children[selectedTab]}</TabContent>
    </Wrapper>
  );
};

export default Tabs;
