import React from 'react';
// constants
import { Color } from 'constants/colors';
// styled
import { StyledButton } from './styled';

export interface ButtonProps {
  fullWidth?: boolean;
  color?: string;
  textColor?: string;
  onClick?: () => void;
  showBorder?: boolean;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  fullWidth = true,
  color = Color.clearChill,
  textColor = Color.white,
  showBorder = false,
  onClick = () => {},
  children,
}) => {
  return (
    <StyledButton fullWidth={fullWidth} color={color} textColor={textColor} showBorder={showBorder} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default Button;
