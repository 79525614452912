import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import { Color } from 'constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  height: 386px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Color.clearChill};
`;

export const IconWrapper = styled(motion.div)``;

export const HeaderText = styled.div`
  color: ${Color.nero};
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  text-align: center;
`;

export const PriceText = styled.div`
  font-size: 38px;
  line-height: 46px;
  color: ${Color.nero};
  font-family: 'Inter-SemiBold', sans-serif;
  text-align: center;
  margin-top: 14px;
`;

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.silver};
  color: ${Color.white};
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 16px;
  border-radius: 100%;
  align-self: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  font-size: 14px;
  gap: 8px;
`;

export const NoteText = styled.div`
  font-size: 14px;
  margin-top: 24px;
  text-align: center;
  line-height: 22px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 18px;
`;
