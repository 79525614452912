import React from 'react';
// libs
import { AnimatePresence } from 'framer-motion';
// styled
import { Content, Overlay, Wrapper } from './styles';

export interface ModalProps {
  isOpened: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const overlayVariants = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      duration: 0.15,
      ease: [1, 0, 0, 1],
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      duration: 0.15,
      ease: [1, 0, 0, 1],
    },
  },
};

const Modal: React.FC<ModalProps> = ({ isOpened, onClose, children }) => {
  return (
    <AnimatePresence>
      {isOpened && (
        <Wrapper>
          <Overlay onClick={onClose} initial="hidden" animate="visible" exit="hidden" variants={overlayVariants} />
          <Content initial={{ y: '100vh' }} animate={{ y: 0 }} exit={{ y: '100vh' }} transition={{ duration: 0.3 }}>
            {children}
          </Content>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Modal;
