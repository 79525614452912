import React, { useCallback, useEffect, useRef, useState } from 'react';
// libs
import { useNavigate } from 'react-router-dom';
import { DropzoneRef } from 'react-dropzone';
import { AnimatePresence } from 'framer-motion';
// components
import AnimatedPage from 'components/AnimatedPage';
import Upload from 'components/Upload';
import Modal from 'components/Modal';
import ModalContent from './components/ModalContent';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
// hooks
import { useHeaderContext } from 'providers/HeaderProvider';
// constants
import { Color } from 'constants/colors';
// styled
import { Wrapper } from './styled';

const PrivateMessageUploadPage: React.FC = () => {
  const navigate = useNavigate();
  const dropzoneRef = useRef<DropzoneRef>(null);
  const [image, setImage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const { setHeaderValues, isNavigatedByBackButton } = useHeaderContext();

  const handleIsPreviewChange = (isPreview: boolean) => setIsPreview(isPreview);
  const handleIsLoadingChange = (isLoading: boolean) => setIsLoading(isLoading);
  const handleImageChange = (image: string | undefined) => setImage(image);
  const handleIsModalOpenChange = (isOpen: boolean) => setIsModalOpened(isOpen);

  const openDropzoneDialog = useCallback(() => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  }, []);

  const handleProceedImage = useCallback((img: string) => {
    setImage(img);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setHeaderValues({
      isPrimary: false,
      title: 'oli_sykes',
      color: Color.nero,
      bgColor: Color.white,
      showDivider: true,
      shouldBeAnimated: false,
      handleBackClick: () => {
        setHeaderValues({ shouldBeAnimated: true });
        isNavigatedByBackButton.current = true;
        navigate(-1);
      },
    });
  }, [isNavigatedByBackButton, navigate, setHeaderValues]);

  useEffect(() => {
    if (!isLoading && isPreview && !image) {
      setIsPreview(false);
    }
  }, [image, isLoading, isPreview]);

  return (
    <>
      <AnimatedPage>
        <Wrapper>
          <Upload
            dropzoneRef={dropzoneRef}
            proceedImage={handleProceedImage}
            onFileDialogCancel={() => setIsLoading(false)}
          >
            {!isChatStarted ? (
              <AnimatePresence exitBeforeEnter initial={false}>
                {!isPreview ? (
                  <FirstStep
                    openDropzoneDialog={openDropzoneDialog}
                    setIsPreview={handleIsPreviewChange}
                    setIsLoading={handleIsLoadingChange}
                    image={image}
                  />
                ) : (
                  <SecondStep
                    image={image}
                    isLoading={isLoading}
                    setIsPreview={handleIsPreviewChange}
                    setIsLoading={handleIsLoadingChange}
                    setImage={handleImageChange}
                    setIsModalOpened={handleIsModalOpenChange}
                  />
                )}
              </AnimatePresence>
            ) : (
              <ThirdStep image={image} />
            )}
          </Upload>
        </Wrapper>
      </AnimatedPage>
      <Modal isOpened={isModalOpened} onClose={() => setIsModalOpened(false)}>
        <ModalContent
          closeModal={() => {
            setIsChatStarted(true);
            setIsModalOpened(false);
          }}
        />
      </Modal>
    </>
  );
};

export default PrivateMessageUploadPage;
