import React from 'react';
// components
import IconButton from 'components/IconButton';
import AnimatedIconButton from 'components/AnimatedIconButton';
// constants
import { Color } from 'constants/colors';
// icons
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CheckOutlinedIcon } from 'assets/icons/check-outlined.svg';
// styled
import {
  ConfirmText,
  Preview,
  Wrapper,
  PreviewImage,
  TopContainer,
  MessageText,
  PrepareText,
  BottomContainer,
  ButtonsContainer,
  PreviewContainer,
  Avatar,
} from './styled';

export interface SecondStepProps {
  image: string | undefined;
  isLoading: boolean;
  setIsPreview: (isPreview: boolean) => void;
  setIsLoading: (setIsLoading: boolean) => void;
  setImage: (image: string | undefined) => void;
  setIsModalOpened: (isOpened: boolean) => void;
}

const transition = {
  duration: 0.15,
  bounce: 0,
};

const variants = {
  exit: { x: '-20%' },
  enter: {
    x: '0%',
  },
};

const SecondStep: React.FC<SecondStepProps> = ({
  image,
  isLoading,
  setIsPreview,
  setIsLoading,
  setImage,
  setIsModalOpened,
}) => {
  return (
    <Wrapper>
      <TopContainer>
        <MessageText>
          <b>You</b> are going to send a message to <b>oli_sykes</b>
        </MessageText>
        <PreviewContainer>
          {!image ? (
            <Preview>
              <PrepareText>Preparing...</PrepareText>
            </Preview>
          ) : (
            <PreviewImage src={image} />
          )}
          <Avatar>Ji</Avatar>
        </PreviewContainer>
      </TopContainer>
      <BottomContainer>
        <ConfirmText>Confirm if this is the correct video.</ConfirmText>
        <ConfirmText>If not - go back and try again.</ConfirmText>
        <ButtonsContainer variants={variants} initial="exit" animate="enter" exit="exit" transition={transition}>
          <IconButton
            icon={<ArrowLeftIcon width="16px" height="16px" />}
            color={Color.rougeSarde}
            borderColor={Color.rougeSarde}
            onClick={() => {
              setImage(undefined);
              setIsPreview(false);
              setIsLoading(false);
            }}
          />
          <AnimatedIconButton
            onClick={() => setIsModalOpened(true)}
            icon={<CheckOutlinedIcon />}
            isLoading={isLoading}
          />
        </ButtonsContainer>
      </BottomContainer>
    </Wrapper>
  );
};

export default SecondStep;
