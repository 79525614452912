import styled from '@emotion/styled/macro';
import { Color } from 'constants/colors';

export const Wrapper = styled.div`
  width: calc(50% - 7px);
  height: 156px;
  border: 1px solid ${Color.orochimaru};
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0 rgba(140, 140, 140, 0.3);

  @media (max-width: 370px) {
    width: 100%;
  }
`;

export const IconWrapper = styled.div<{ color: string }>`
  margin-bottom: 12px;
  color: ${({ color }) => color};
`;

export const Title = styled.div`
  color: ${Color.nero};
  font-size: 16px;
  font-family: 'Inter-SemiBold', sans-serif;
  margin-bottom: 8px;
`;

export const Container = styled.div`
  margin-bottom: 16px;
`;
