import React from 'react';
// constants
import { Color } from 'constants/colors';
// styled
import { StyledLoader } from './styled';

export interface LoaderProps {
  width?: number;
  height?: number;
  borderWidth?: number;
  color?: string;
}

export const Loader: React.FC<LoaderProps> = ({ width = 48, height = 48, borderWidth = 2, color = Color.white }) => (
  <StyledLoader width={width} height={height} borderWidth={borderWidth} color={color}>
    <div />
    <div />
    <div />
    <div />
  </StyledLoader>
);

export default Loader;
