import React from 'react';
// libs
import { useNavigationType } from 'react-router-dom';
// hooks
import { useHeaderContext } from 'providers/HeaderProvider';
// icons
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
// styled
import { Container, Logo, LogoContainer, NavContainer, Profile, Title, Wrapper } from './styled';

const bellIconStyles = { cursor: 'pointer' };

const transition = {
  duration: 0.3,
  ease: [1, 0, 0, 1],
};

const PrimaryHeader: React.FC = () => {
  const navigationType = useNavigationType();

  const { isNavigatedByBackButton } = useHeaderContext();

  const variants = {
    exit: () => {
      return {
        x: '50%',
        opacity: 0,
        transition: transition,
      };
    },
    enter: () => {
      return {
        x: '0%',
        opacity: 1,
        transition,
      };
    },
    exitWrapper: {
      transition: { delay: 0.3 },
      backgroundColor: 'transparent',
      transitionEnd: {
        backgroundColor: 'transparent',
      },
    },
    enterWrapper: {
      transition: { delay: 0.3 },
      backgroundColor: 'transparent',
      transitionEnd: {
        backgroundColor: 'white',
      },
    },
  };

  return (
    <Wrapper variants={variants} initial="exitWrapper" animate="enterWrapper" exit="exitWrapper">
      <Container>
        <LogoContainer>
          <Logo />
          <Title>nilu</Title>
        </LogoContainer>
        <NavContainer
          variants={variants}
          initial={navigationType === 'POP' && !isNavigatedByBackButton.current ? false : 'exit'}
          animate="enter"
          exit="exit"
        >
          <BellIcon style={bellIconStyles} />
          <Profile>Ji</Profile>
        </NavContainer>
      </Container>
    </Wrapper>
  );
};

export default PrimaryHeader;
