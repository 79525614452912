import React, { useCallback, useContext, useState, useRef, MutableRefObject } from 'react';
// libs
import { detect } from 'detect-browser';

export const isIOS = detect()?.os === 'iOS';

export interface IHeaderContext {
  isPrimary: boolean;
  title: string;
  color: string;
  bgColor: string;
  shouldBeAnimated: boolean;
  showDivider: boolean;
  setHeaderValues: (values: ISetHeaderValuesParams) => void;
  handleBackClick: () => void;
  isNavigatedByBackButton: MutableRefObject<boolean>;
}

export interface HeaderProviderProps {
  children: React.ReactNode;
}

const HeaderContext = React.createContext<IHeaderContext>({
  isPrimary: false,
  title: '',
  color: '',
  bgColor: '',
  showDivider: false,
  shouldBeAnimated: false,
  setHeaderValues: () => {},
  handleBackClick: () => {},
  isNavigatedByBackButton: { current: !isIOS },
});

export interface ISetHeaderValuesParams {
  isPrimary?: boolean;
  title?: string;
  color?: string;
  bgColor?: string;
  showDivider?: boolean;
  shouldBeAnimated?: boolean;
  handleBackClick?: () => void;
}

export const useHeaderContext = () => useContext(HeaderContext);

const HeaderProvider: React.FC<HeaderProviderProps> = ({ children }) => {
  const [isPrimary, setIsPrimary] = useState(false);
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [shouldBeAnimated, setShouldBeAnimated] = useState(false);
  const [showDivider, setShowDivider] = useState(false);
  const [handleBackClick, setHandleBackClick] = useState<() => void>(() => {});

  const isNavigatedByBackButton = useRef(!isIOS);

  const setHeaderValues = useCallback(
    ({ isPrimary, title, color, bgColor, shouldBeAnimated, showDivider, handleBackClick }: ISetHeaderValuesParams) => {
      isPrimary !== undefined && setIsPrimary(isPrimary);
      title && setTitle(title);
      color && setColor(color);
      bgColor && setBgColor(bgColor);
      shouldBeAnimated !== undefined && setShouldBeAnimated(shouldBeAnimated);
      showDivider !== undefined && setShowDivider(showDivider);
      handleBackClick && setHandleBackClick(() => handleBackClick);
    },
    [],
  );

  return (
    <HeaderContext.Provider
      value={{
        isPrimary,
        title,
        color,
        bgColor,
        shouldBeAnimated,
        showDivider,
        handleBackClick,
        setHeaderValues,
        isNavigatedByBackButton,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
