import React from 'react';
// hooks
import { useHeaderContext } from 'providers/HeaderProvider';
// icons
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
// constants
import { Color } from 'constants/colors';
// styled
import { Container, Divider, MoreIconWrapper, MoreIconWrapperAnimated, Title, Wrapper } from './styled';

export interface SecondHeaderProps {}

const transition = {
  duration: 0.3,
  ease: [1, 0, 0, 1],
};

const variants = {
  exit: {
    x: '-30%',
    opacity: 0,
    transition: transition,
  },
  enter: {
    x: '0%',
    opacity: 1,
    transition,
  },
  exitDivider: {
    opacity: 0,
    backgroundColor: 'transparent',
    zIndex: 0,
    transition: { duration: 0.3, delay: 2 },
  },
  enterDivider: {
    backgroundColor: 'black',
    opacity: 1,
    transition: { duration: 0.3, delay: 2 },
    transitionEnd: {
      zIndex: 1,
    },
  },
  showDivider: {
    backgroundColor: Color.snowFlake,
    opacity: 1,
    transition: {},
    transitionEnd: {
      zIndex: 1,
    },
  },
  hideDivider: {
    opacity: 0,
    backgroundColor: 'transparent',
    zIndex: 0,
    transition: {},
  },
};

const arrowLeftIconStyles = { cursor: 'pointer' };

const SecondaryHeader: React.FC<SecondHeaderProps> = ({}) => {
  const { title, color, shouldBeAnimated, showDivider, handleBackClick } = useHeaderContext();

  return (
    <React.Fragment>
      <Wrapper>
        <Container color={color}>
          <ArrowLeftIcon onClick={handleBackClick} style={arrowLeftIconStyles} />
          <Title>{title}</Title>
          {shouldBeAnimated ? (
            <MoreIconWrapperAnimated variants={variants} initial="exit" animate="enter" exit="exit">
              <MoreIcon />
            </MoreIconWrapperAnimated>
          ) : (
            <MoreIconWrapper>
              <MoreIcon />
            </MoreIconWrapper>
          )}
        </Container>
      </Wrapper>
      <Divider variants={variants} initial={false} animate={showDivider ? 'showDivider' : 'hideDivider'} />
    </React.Fragment>
  );
};

export default SecondaryHeader;
