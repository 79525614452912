import React from 'react';
// icons
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
// styled
import { Price, PriceContainer, StyledButton, Title } from './styled';

export interface PriceButton {
  title: string;
  price: number | string;
  onClick: () => void;
}

const PriceButton: React.FC<PriceButton> = ({ title, price, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <Title>{title}</Title>
      <PriceContainer>
        <Price>${price}</Price>
        <ArrowRightIcon />
      </PriceContainer>
    </StyledButton>
  );
};

export default PriceButton;
