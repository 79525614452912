export enum Color {
  black = '#000000',
  white = '#ffffff',
  orochimaru = '#d9d9d9',
  shadowMountain = '#595959',
  blackSuede = '#434343',
  moreThanAWeek = '#8c8c8c',
  silver = '#bfbfbf',
  nero = '#262626',
  clearChill = '#1890ff',
  snowFlake = '#f0f0f0',
  bitOfBerry = '#e4569f',
  majorelleBlue = '#5e48e0',
  bulmaHair = '#349c69',
  sportsFan = '#df7d1c',
  iceEffect = '#bae7ff',
  brokenBlue = '#69b7ff',
  whiteSmoke = '#f5f5f5',
  rougeSarde = '#f5222d',
  preciousPersimmon = '#ff7a45',
  transparent = 'transparent',
  white15 = 'rgba(255, 255, 255, 0.15)',
}
