import React from 'react';
// libs
import Router from 'components/Router';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <Router />
    </HelmetProvider>
  );
};

export default App;
